import { FunctionComponent, useEffect } from 'react'

import {
  RyuBreak,
  RyuText,
  RyuPad,
  RyuFlex,
  RyuImage,
  RyuIcon,
  useRyuThemeState,
  RyuPane,
  RyuFlexGrow,
} from '@ramp/ryu'

import { FullScreenConfetti } from '../FullScreenConfetti'
import { styled } from 'styled-components'
import { LeadModel } from '~/src/models/leads/LeadModel'
import customerLogoFooter from '~/src/images/customer-logo-footer-short-light.png'
import customerLogoFooterDark from '~/src/images/customer-logo-footer-short-dark.png'
import contactSalesAvatarStack from '~/src/images/contact-sales-avatar-stack.png'
import { KenStack } from '~/src/components/ken/KenStack'
import qualifiedPlaceholder from '~/src/images/chatbot-placeholder.png'
import applyForRampPlaceholder from '~/src/images/apply-placeholder.png'
import { useQualified } from '~/src/hooks/useQualified'
import { useHistory } from 'react-router-dom'
import { SalesDemoFormInputs } from './SalesDemoFormInputs'
import { SalesDemoForm } from '~/src/routes/UserQualification/pages/sales-demo-type'
import { trackEvent } from '~/src/utils/analytics'

type Props = {
  onSubmit: (data: SalesDemoForm) => void
  onOverride: () => void
  bookedMeeting?: boolean
  initialValues: SalesDemoForm
  lead: LeadModel | null
}

export const SalesDemoTreatment: FunctionComponent<Props> = ({
  onSubmit,
  onOverride,
  bookedMeeting,
  initialValues,
  lead,
}) => {
  const [theme] = useRyuThemeState()
  const { startQualified } = useQualified()
  const history = useHistory()

  useEffect(() => {
    trackEvent('Qualified Chat Bot Experiment V2 Viewed')
  }, [])

  if (bookedMeeting) {
    onOverride()
  }

  return (
    <SalesDemoLayout>
      <FullScreenConfetti />

      <RyuFlex responsive={true} alignItems='flexStart'>
        <SalesDemoFormContainer>
          <ContactSalesAvatarStack src={contactSalesAvatarStack} />
          <KenStack gapSize='s'>
            <RyuText.h2 size='l' paddingVertical='none'>
              Let's get you connected
              <br />
              with a Ramp expert
            </RyuText.h2>

            <RyuFlex gapSize='s'>
              <RyuIcon type='help-circle' />
              <RyuText.span>Answers to any questions you have about Ramp</RyuText.span>
            </RyuFlex>

            <RyuFlex gapSize='s'>
              <RyuIcon type='dollar-sign' />
              <RyuText.span>A custom assessment to see how much Ramp will save you</RyuText.span>
            </RyuFlex>

            <RyuFlex gapSize='s'>
              <RyuIcon type='merge' />
              <RyuText.span>Information on how Ramp can consolidate your financial systems</RyuText.span>
            </RyuFlex>
            <SalesDemoFormInputs lead={lead} initialValues={initialValues} onSubmit={onSubmit} />
            <RyuPad size='s'>
              <RyuImage
                fit='fill'
                position='center'
                src={theme === 'ryu-dark' ? customerLogoFooterDark : customerLogoFooter}
              />
            </RyuPad>
          </KenStack>
        </SalesDemoFormContainer>
        <RyuPad size='l'>
          <KenStack gapSize='m'>
            <RyuText.h4 size='s' paddingVertical='none'>
              Prefer something immediate?
            </RyuText.h4>
            <SidebarCard
              title='Start live chat'
              imgSrc={qualifiedPlaceholder}
              description='Get your questions answered in real-time'
              onClick={() => {
                startQualified()
                trackEvent('Qualified Chat Bot Experiment V2 Clicked', { event_type: 'start-live-chat' })
              }}
            />
            <SidebarCard
              imgSrc={applyForRampPlaceholder}
              title='Apply for Ramp'
              description='Begin your application today'
              onClick={() => {
                history.push('/sign-up')
                trackEvent('Qualified Chat Bot Experiment V2 Clicked', { event_type: 'sign-up' })
              }}
            />
          </KenStack>
        </RyuPad>
      </RyuFlex>

      <RyuBreak size='xl' />
    </SalesDemoLayout>
  )
}

function SidebarCard({
  title,
  description,
  onClick,
  imgSrc,
}: {
  title: string
  description: string
  onClick: () => void
  imgSrc: string
}) {
  return (
    <button onClick={onClick}>
      <RyuPane paddingSize='m'>
        <KenStack gapSize='s'>
          <RyuImage
            src={imgSrc}
            alt={title}
            fit='cover'
            position='center'
            height='200rem'
            width='100%'
          />
          <RyuFlexGrow>
            <RyuFlex justifyContent='spaceBetween'>
              <div>
                <RyuText.h3 size='xs' paddingVertical='none' align='left'>
                  {title}
                </RyuText.h3>
                <RyuText.div size='xs' color='hushed' align='left'>
                  {description}
                </RyuText.div>
              </div>
              <RyuIcon type='arrow-up-right' size='l' />
            </RyuFlex>
          </RyuFlexGrow>
        </KenStack>
      </RyuPane>
    </button>
  )
}

const SalesDemoFormContainer = styled.div`
  max-width: 50rem;
  height: 100vh;
  padding-right: ${({ theme }) => theme.ryuSpacing[24]}rem;
  padding-left: ${({ theme }) => theme.ryuSpacing[6]}rem;
  border-right: 1px solid ${({ theme }) => theme.ryuColors.lightShade};
`

const SalesDemoLayout = styled.div`
  max-width: 90rem;
  margin: 0 auto;
  height: calc(100vh - 10rem);
  position: relative;
`

const ContactSalesAvatarStack = styled.img`
  max-width: 10rem;
`
