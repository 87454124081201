import { useContext, useEffect } from 'react'
import { Loading } from '~/src/components/Loading'
import { LeadContext, LeadExperiment } from '~/src/models/leads'

const EXPERIMENT_TIMEOUT = 4000

/**
 * In case the experiment is not set after a failed API call, we will set it to the control after a timeout
 */
function SalesDemoLoading() {
  const { setLead } = useContext(LeadContext)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLead((prevLead) => {
        if (prevLead) {
          return {
            ...prevLead,
            experiments: prevLead.experiments
              ? [...prevLead.experiments, LeadExperiment.QualifiedChatBotControl]
              : [LeadExperiment.QualifiedChatBotControl],
          }
        }

        return prevLead
      })
    }, EXPERIMENT_TIMEOUT)

    return () => clearTimeout(timeout)
  }, [setLead])

  return <Loading />
}

export default SalesDemoLoading
