import { RyuBreak, RyuText } from '@ramp/ryu'
import { KenRampLogo } from '~/src/components/ken/KenRampLogo'
import CheckList from './CheckBoxListItem'
import { styled } from 'styled-components'
import ImageCarousel from '~/src/components/ImageCarousel'

const LeftSideContent = styled.div`
  color: white;
  padding: 72px 64px;
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 1024px;
  z-index: 1;
  position: relative;
  float: right;
  @media (max-width: 768px) {
    padding: 48px 36px;
    float: none;
  }
`

const GreenBackground = styled.div`
  background-color: #2f4842;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`

const LeftSideContainer = styled.div`
  position: relative;
  height: 100%;
`

const DetailText = styled(RyuText)`
  color: #c6c6a7;
  font-feature-settings: 'case' on;
  @media (max-width: 767px) {
    display: none;
  }
`

function ContactUsLeftSide() {
  return (
    <LeftSideContainer>
      <LeftSideContent>
        <KenRampLogo />
        <RyuBreak size='xl' />
        <RyuText.h2>Contact our team</RyuText.h2>
        <CheckList>
          <span>Unlimited virtual and physical cards</span>
          <span>1000+ accounting, ERP, and software integrations</span>
          <span>Accounting automation software to speed up monthly close</span>
        </CheckList>
        <RyuBreak size='xl' />
        <DetailText size='m'>Trusted by 25,000+ businesses</DetailText>
        <RyuBreak size='xl' />
        <ImageCarousel hideOnMobile={true} />
      </LeftSideContent>
      <GreenBackground />
    </LeftSideContainer>
  )
}

export default ContactUsLeftSide
