import { styled } from 'styled-components'

import { RyuBreak, RyuLoadingSpinner, RyuText } from '@ramp/ryu'

import { UsernameSignUpFormFields } from './UsernameSignUpFormFields'
import { KenForm } from '../ken/KenForm/KenForm'
import { KenFormSubmitButton } from '../ken/KenForm/KenFormSubmitButton'
import { SignUpFormFields, useSignUp } from '~/src/hooks/useSignUp'
import { UserAlreadyExists } from '../UserAlreadyExists'
import { KenFieldCheckboxGroup } from '~/src/components/ken/KenForm/KenFieldCheckboxGroup'

export type PossibleInterestedProducts =
  | 'interested_in_corporate_cards'
  | 'interested_in_bill_payments'
  | 'interested_in_procurement'

export const INTERESTED_PRODUCTS_LABEL_MAP: Record<PossibleInterestedProducts, string> = {
  interested_in_corporate_cards: 'Corporate Cards and Expense Management',
  interested_in_bill_payments: 'Accounts Payable',
  interested_in_procurement: 'Procurement',
}

export function UsernameSignUp(params: any) {
  const { leadEmail } = params
  const {
    leadCheckForExistingIdentityIsLoading,
    allowPersonalEmails,
    form,
    leadEmailMatchesExistingIdentity,
    existingBusinessError,
    onSubmit,
    validateEmail,
  } = useSignUp(params)

  if (leadCheckForExistingIdentityIsLoading) {
    return (
      <SpinnerLayout>
        <RyuLoadingSpinner size='xxl' />
      </SpinnerLayout>
    )
  }

  if (leadEmailMatchesExistingIdentity && leadEmail) {
    return <UserAlreadyExists email={leadEmail} />
  }

  return (
    <FullWidth>
      <KenForm
        form={form}
        render={({ formProps }) => (
          <form {...formProps} noValidate={true}>
            <UsernameSignUpFormFields
              form={form}
              allowPersonalEmails={allowPersonalEmails}
              validateEmail={validateEmail}
              existingBusinessError={existingBusinessError}
            />

            <RyuBreak size='s' />

            <RyuText.span size='s' color='hushed'>
              Products you’re interested in
            </RyuText.span>

            <RyuBreak size='xs' />

            <KenFieldCheckboxGroup<
              SignUpFormFields,
              'interested_in_products',
              PossibleInterestedProducts
            >
              name='interested_in_products'
              gapSize='xs'
              rules={{ required: 'Select at least one option' }}
              caption='Don’t worry. This is not a commitment to any product.'
              items={[
                'interested_in_corporate_cards',
                'interested_in_bill_payments',
                'interested_in_procurement',
              ]}
              parseItem={(value) => ({
                label: INTERESTED_PRODUCTS_LABEL_MAP[value],
                value,
                key: value,
                variant: 'lean',
                iconSide: 'right',
              })}
            />

            <RyuBreak size='xl' />

            <KenFormSubmitButton color='primary' variant='prime' fullWidth={true} _textAlign='center'>
              Start application
            </KenFormSubmitButton>
          </form>
        )}
        onSubmit={onSubmit}
      />
    </FullWidth>
  )
}

const SpinnerLayout = styled.div`
  height: 30rem;
  width: 30rem;
`

const FullWidth = styled.div`
  width: 100%;
`
