import { RyuText } from '@ramp/ryu'
import { Children, ReactNode } from 'react'
import { styled } from 'styled-components'

const FixedSizeDiv = styled.div`
  width: 24px;
  height: 24px;
`

function Check() {
  return (
    <FixedSizeDiv>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M20.5303 5.46967C20.8232 5.76256 20.8232 6.23744 20.5303 6.53033L9.53033 17.5303C9.23744 17.8232 8.76256 17.8232 8.46967 17.5303L3.46967 12.5303C3.17678 12.2374 3.17678 11.7626 3.46967 11.4697C3.76256 11.1768 4.23744 11.1768 4.53033 11.4697L9 15.9393L19.4697 5.46967C19.7626 5.17678 20.2374 5.17678 20.5303 5.46967Z'
          fill='#C6C6A7'
        />
      </svg>
    </FixedSizeDiv>
  )
}

const StyledListItem = styled(RyuText.li)`
  display: flex;
  align-items: center;
  gap: 1rem;
  list-style: none;
  :not(last-child) {
    margin-bottom: 16px;
  }
`

const StyledUL = styled(RyuText.ul)`
  margin-left: 0 !important;
`

function createCheckListItem(item: ReactNode) {
  return (
    <StyledListItem>
      <Check />
      {item}
    </StyledListItem>
  )
}

interface CheckListItemProps {
  children: ReactNode | ReactNode[]
}

export default function CheckList({ children }: CheckListItemProps) {
  return <StyledUL>{Children.map(children, createCheckListItem)}</StyledUL>
}
