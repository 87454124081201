import { styled } from 'styled-components'

import { Fragment, useContext, useEffect, useState } from 'react'

import { RyuBreak, RyuFlex, RyuPad, RyuText, useRyuBreakpoints } from '@ramp/ryu'

import { KenRampLogo } from '~/src/components/ken/KenRampLogo'
import { useKenSearchParams } from '~/src/hooks/useKenSearchParams'
import { UsernameSignUp } from '~/src/components/UsernameSignUp'

import { GoogleTagManager } from '~/src/scripts/gtm'
import { isProduction } from '~/src/utils/env'
import { trackEvent } from '~/src/utils/analytics'
import { FullScreenConfetti } from '~/src/routes/UserQualification/FullScreenConfetti'

import { pageProperties } from '~/src/utils/analytics/pageEnrichment'
import { TestimonialCarouselSidebar } from '~/src/routes/UserQualification/TestimonialCarouselSidebar'
import { LeadContext, LeadExperiment, useUpdateLeadModel } from '~/src/models/leads'
import { logError } from '~/src/utils/errors'
import { Loading } from '~/src/components/Loading'

export function SelfService() {
  const [{ email: leadEmail, first_name: leadFirstName, last_name: leadLastName, invited = false }] =
    useKenSearchParams({
      schema: { email: 'string', first_name: 'string', last_name: 'string', invited: 'boolean' },
    })

  useEffect(() => {
    if (!leadEmail) {
      return
    }

    trackEvent('Self Service Page Viewed', { email: leadEmail, ...pageProperties() })
    trackEvent('SS CS DR VE Total Page Viewed', { email: leadEmail, ...pageProperties() })
  }, [leadEmail])
  const [hideClaimAccountMessage, setHideClaimAccountMessage] = useState(false)

  const isMobile = useRyuBreakpoints({ maxWidth: 'tablet' })
  const isDesktop = useRyuBreakpoints({ minWidth: 'desktop' })
  const [{ location }] = useKenSearchParams({
    schema: { location: 'string' },
  })

  const isFromSalesQualified = location === 'sales-qualified'

  const { lead, setLead } = useContext(LeadContext)
  const updateLeadModel = useUpdateLeadModel()

  const [isAssigningSelfServeCopyExperiment, setIsAssigningSelfServeCopyExperiment] = useState(
    // If isFromSalesQualified, user will never see the self serve copy experiment.
    !isFromSalesQualified &&
      !lead?.experiments?.includes(LeadExperiment.SelfServeCopyH1) &&
      !lead?.experiments?.includes(LeadExperiment.SelfServeCopyH2)
  )

  useEffect(() => {
    const maybeAssignSelfServeCopyExperiment = async () => {
      if (isAssigningSelfServeCopyExperiment) {
        const randomlyAssignedExperiment =
          Math.random() < 0.5 ? LeadExperiment.SelfServeCopyH1 : LeadExperiment.SelfServeCopyH2

        try {
          await updateLeadModel(lead?.id, { experiment: randomlyAssignedExperiment })
          setLead((prevLead) => {
            if (prevLead) {
              return {
                ...prevLead,
                experiments: [...(prevLead.experiments ?? []), randomlyAssignedExperiment],
              }
            }

            return prevLead
          })
        } catch (error) {
          logError.medium(error)
        }

        // Regardless of assignment success or failure, we will not try again. Fallback to defaults
        setIsAssigningSelfServeCopyExperiment(false)
      }
    }

    maybeAssignSelfServeCopyExperiment()

    // Set isAssigningSelfServeCopyExperiment to false after 4 seconds, regardless of success or
    // failure of maybeAssignSelfServeCopyExperiment
    const timeout = setTimeout(() => {
      setIsAssigningSelfServeCopyExperiment(false)
    }, 4000)

    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Track views of the self serve copy experiment
  useEffect(() => {
    // If isFromSalesQualified, user will never see the self serve copy experiment.
    if (isFromSalesQualified) {
      return
    }

    const selfServiceCopyExperiment = lead?.experiments?.find(
      (experiment) =>
        experiment === LeadExperiment.SelfServeCopyH1 || experiment === LeadExperiment.SelfServeCopyH2
    )

    if (selfServiceCopyExperiment) {
      trackEvent('Self Service Copy Experiment Viewed', { segment: selfServiceCopyExperiment })
    }
  }, [lead?.experiments, isFromSalesQualified])

  if (isAssigningSelfServeCopyExperiment) {
    return <Loading />
  }

  return (
    <Fragment>
      {isProduction && <GoogleTagManager />}

      <FullScreenConfetti />

      <SelfServiceLayout>
        <SelfServiceFormLayout>
          <SelfServiceFormLayoutContent $isMobile={isMobile}>
            <RyuPad size='m'>
              <RyuBreak size='xxl' />
              <RyuBreak size='xxl' />

              <RyuFlex justifyContent='center'>
                <KenRampLogo />
              </RyuFlex>

              <RyuBreak size='xl' />

              <RyuText.h size='l' paddingVertical='none' align='center'>
                {getWelcomeText()}
              </RyuText.h>

              {!hideClaimAccountMessage && (
                <Fragment>
                  <RyuBreak />

                  {!!getSubText() && (
                    <RyuText.div size='s' color='hushed' align='center'>
                      {getSubText()}
                    </RyuText.div>
                  )}

                  <RyuBreak size='xl' />
                </Fragment>
              )}

              <UsernameSignUp
                leadEmail={leadEmail}
                leadFirstName={leadFirstName}
                leadLastName={leadLastName}
                onLeadEmailMatchesExistingIdentity={() => setHideClaimAccountMessage(true)}
              />

              <RyuBreak />
            </RyuPad>
          </SelfServiceFormLayoutContent>
        </SelfServiceFormLayout>

        {isDesktop && <TestimonialCarouselSidebar />}
      </SelfServiceLayout>
    </Fragment>
  )

  function getWelcomeText(): string {
    if (isFromSalesQualified) {
      return 'We’re looking forward to connecting.'
    }

    if (invited) {
      return 'You’re invited to apply!'
    }

    if (lead?.experiments?.includes(LeadExperiment.SelfServeCopyH1)) {
      return 'Claim your account'
    }

    if (lead?.experiments?.includes(LeadExperiment.SelfServeCopyH2)) {
      return 'Ramp is a great fit!'
    }

    // Nov 2024 status quo
    return 'Ramp is a great fit!'
  }

  function getSubText(): string | null {
    if (isFromSalesQualified) {
      return 'In the meantime, secure your credit limit and get access to the Ramp platform.'
    }

    if (lead?.experiments?.includes(LeadExperiment.SelfServeCopyH1)) {
      return 'Complete your application in as little as 10 minutes.'
    }

    if (lead?.experiments?.includes(LeadExperiment.SelfServeCopyH2)) {
      return null
    }

    // Nov 2024 status quo
    return 'Complete your application in as little as 10 minutes.'
  }
}

const SelfServiceLayout = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex: 0 0 100%;
`

const SelfServiceFormLayout = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
`

const SelfServiceFormLayoutContent = styled.div<{ $isMobile: boolean }>`
  min-width: ${({ $isMobile }) => `${$isMobile ? '100%' : '45rem'}`};
`
