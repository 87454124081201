import { Dispatch, SetStateAction } from 'react'

export enum PreApplicationStep {
  COMPANY_SIZE = 'COMPANY_SIZE',
  MONTHLY_SPEND = 'MONTHLY_SPEND',
  GLA = 'GLA',
  UNQUALIFIED = 'UNQUALIFIED',
  SELF_SERVICE_QUALIFIED = 'SELF_SERVICE_QUALIFIED',
  SALES_QUALIFIED = 'SALES_QUALIFIED',
  DEMO_REQUESTED = 'DEMO_REQUESTED',
  INTERESTED_PRODUCTS = 'INTERESTED_PRODUCTS',
}

export type RedirectToPath =
  | PreApplicationStep.SELF_SERVICE_QUALIFIED
  | PreApplicationStep.DEMO_REQUESTED

export enum RoutingDirection {
  SALES_QUALIFIED = 'sales_qualified',
  SALES_OPTIONAL = 'sales_optional',
  SELF_SERVICE_NO_PREQUAL = 'self_service_no_prequal',
  SELF_SERVICE_PREQUAL = 'self_service_prequal',
  UNQUALIFIED = 'unqualified',
}

export enum MadKuduSegment {
  BAD = 'bad',
  LOW = 'low',
  MEDIUM = 'medium',
  GOOD = 'good',
  VERY_GOOD = 'very good',
}

export enum LeadExperiment {
  SALES_NEW_VARIANT = '2022-04 sales-routed new variant',
  SALES_CONTROL = '2022-04 sales control group',
  SEP_2023_CRO = 'sep-2023-cro-treatment',
  ContactSalesScaledSDR = 'july-2024-contact-sales-scaled-sdr-chilipiper',
  ContactSalesStandardSDR = 'july-2024-contact-sales-sdr-chilipiper',
  QualifiedChatBotControl = 'nov-2024-qualified-chat-bot-control',
  QualifiedChatBotTreatment = 'nov-2024-qualified-chat-bot-treatment',
  SelfServeCopyH1 = 'nov-2024-self-serve-copy-h1',
  SelfServeCopyH2 = 'nov-2024-self-serve-copy-h2',
}

export type ApiLeadModel = Pick<LeadModel, 'id' | 'skipped_prequal' | 'routing_direction'>

export type LeadModel = {
  readonly id: string
  readonly company_email?: string

  readonly employee_count?: string
  readonly has_employee_count?: boolean

  readonly estimated_monthly_spend?: string
  readonly has_estimated_monthly_spend?: boolean

  readonly bank_balance?: string
  readonly has_bank_balance?: boolean

  readonly company_name?: string
  readonly has_company_name?: boolean

  readonly first_name?: string
  readonly has_first_name?: boolean

  readonly last_name?: string
  readonly has_last_name?: boolean

  readonly requested_demo?: boolean
  readonly interested_in_corporate_cards?: boolean
  readonly interested_in_reimbursements?: boolean
  readonly interested_in_bill_payments?: boolean
  readonly interested_in_flex?: boolean
  readonly interested_in_procurement?: boolean

  readonly utm_campaign?: string
  readonly utm_campaign_at?: string
  readonly utm_source?: string
  readonly utm_medium?: string
  readonly utm_term?: string
  readonly utm_content?: string
  readonly promo?: string
  readonly referral_code?: string

  readonly initial_utm_campaign?: string
  readonly initial_utm_campaign_at?: string
  readonly initial_utm_source?: string
  readonly initial_utm_medium?: string
  readonly initial_utm_term?: string
  readonly initial_utm_content?: string
  readonly initial_promo?: string
  readonly initial_referral_code?: string

  readonly skipped_prequal?: boolean
  readonly routing_direction?: RoutingDirection
  readonly score?: MadKuduSegment
  readonly clearbit_employee_count?: number
  readonly is_treatment?: boolean
  readonly gclid?: string | null
  readonly ramp_attid?: string | null
  readonly experiment?: LeadExperiment
  readonly experiments?: LeadExperiment[]
  readonly sales_demo_booked_at?: string
  readonly calendar_load_ms?: number
  readonly calendar_booking_url?: string

  readonly segment_device_uuid?: string
  readonly discovery_method?: string

  readonly job_title?: string
  readonly additional_notes?: string

  isHydrated?: boolean
}

export type LeadContextProps = {
  lead: LeadModel | null
  setLead: Dispatch<SetStateAction<LeadModel | null>>
  setHydratedLead: Dispatch<SetStateAction<LeadModel | null>>
  setIsLeadHydrated: Dispatch<SetStateAction<boolean>>
  isLeadHydrated: boolean
}
